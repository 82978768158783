import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ReactHtmlParser from 'react-html-parser';

import './css/FieldRender.css';

class FieldRender extends Component {

	constructor(props) {

		super(props);

		var options = [];

		if (this.props.type === 'select' && this.props.content) {
			options = this.props.content.split('|');
		}

		this.state = {
			options,
			name: "field_" + this.props.index_field,
			hidden_label: false,
			...this.props,
		}

		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {

		if (["image", "content", "title", "subtitle", "subsubtitle"].includes(this.props.type)) {
			// No need
			return;
		}

		var value = "";

		if (this.state.data) {

			value = this.state.data;

		} else if (this.state.reference && this.state.reference.indexOf('+') > -1) {

			var split = this.state.reference.split('+');
			var values = [];

			for (var i = split.length - 1; i >= 0; i--) {
				if (this.state.references && typeof this.state.references[split[i]] !== 'undefined' && this.state.references[split[i]]) {
					values.push(this.state.references[split[i]]);
				}
			}

			value = values.join(' ');

		} else if (this.state.references && typeof this.state.references[this.state.reference] !== 'undefined' && this.state.references[this.state.reference]) {

			value = this.state.references[this.state.reference];

		} else if (this.state.content && !["select", "select_multiple"].includes(this.props.type)) {

			value = this.state.content;

		}

		if (value === "hidden_label") {
			value = "";
		}

		if (value === "" && this.props.type === "date") {
			value = this.getCurrentDate();
		}

		if (value === "" && this.props.type === "select") {
			value = this.state.options[0];
		}

		if (value === "" && this.props.type === "number") {
			value = "1";
		}

		if (value.length > 0) {
			this.setFieldValue(value);
		}
	}

	setFieldValue(value) {

		this.setState({
			[this.state.name]: value
		});

		let elem = document.getElementsByName(this.state.name)[0];

		if (elem) {
			elem.value = value;
		}

		this.props.onFieldChange(this.state.index_field, value);

	}

	onChange(e) {

		var result = false;

		if (this.props.type === 'checkbox') {
			result = e.target.checked ? "checked" : '';
		} else if (this.props.type === 'select' || this.props.type === 'select_multiple') {

			var options = e.target.options;
			result = [];

			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].selected) {
					result.push(options[i].value);
				}
			}

		} else {
			result = e.target.value;
		}

		this.setFieldValue(result);
	}

	onSignatureEnd(e) {
		this.setFieldValue(this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
	}

	onSignatureClear(e) {
		this.sigPad.clear();
	}

	getCurrentDate() {

		let date = new Date();
		let current_month = (date.getMonth() + 1).toString();
		let day = date.getDate() > 10 ? date.getDate().toString() : '0' + date.getDate().toString();
		let month = current_month > 10 ? current_month : '0' + current_month;

		return date.getFullYear().toString() + '-' + month + '-' + day
	}

	render() {

		if (this.props.type === "date") {
			var defaultValue = this.getCurrentDate();
		}

		var hidden_label = this.state.hidden_label;

		if (['subtitle', 'subsubtitle', 'title'].includes(this.props.type) || this.props.content === "hidden_label" || (this.props.label && this.props.label.length < 1)) {
			hidden_label = true;
		}

		return (
			<div className={`field_wrapper ${this.props.type}_field_wrapper`}>

				{hidden_label === false ?
					<label htmlFor={this.state.name}>{this.props.label}</label>
					: null
				}

				<div className={`field_subwrapper ${this.props.type}_field_subwrapper`}>
					{this.props.type === 'image' ? <img src={this.props.content} alt={this.state.name} /> : null}
					{this.props.type === 'content' ? ReactHtmlParser(this.props.content) : null}
					{this.props.type === 'text' ? <input type="text" className="text_input" reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} /> : null}
					{this.props.type === 'number' ? <input type="number" defaultValue="1" className="number_input" reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} /> : null}
					{this.props.type === 'date' ? <input type="date" className="date_input" reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} defaultValue={defaultValue} /> : null}
					{this.props.type === 'email' ? <input type="email" className="email_input" reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} /> : null}
					{this.props.type === 'checkbox' ? <input type="checkbox" className="checkbox_input" reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} defaultChecked={this.state.data === "checked" || this.state.content === "checked"} /> : null}
					{this.props.type === 'select' || this.props.type === "select_multiple" ?
						<select className="select_input" multiple={this.props.type === "select_multiple"} reference={this.state.reference} name={this.state.name} id={this.state.name} onChange={this.onChange} defaultValue={this.state.options[0]} >
							{this.state.options.map((option_name, index_option_name) => (
								<option key={index_option_name} value={option_name}>{option_name}</option>
							)
							)}
						</select>
						: null}
					{this.props.type === 'title' ? <h2>{ReactHtmlParser(this.props.label)}</h2> : null}
					{this.props.type === 'subtitle' ? <h3>{ReactHtmlParser(this.props.label)}</h3> : null}
					{this.props.type === 'subsubtitle' ? <h4>{ReactHtmlParser(this.props.label)}</h4> : null}
					{this.props.type === 'signature' ? <div className="signature_canvas_wrapper"><SignatureCanvas penColor='black' canvasProps={{ width: 300, height: 150, className: 'signature_input' }} {...this.props} name={this.state.name} id={this.state.name} ref={(ref) => { this.sigPad = ref }} onChange={this.onChange} onEnd={(e) => { this.onSignatureEnd(e); }} /><button className="signature_canvas_clear" onClick={(e) => { this.onSignatureClear(e) }} >Effacer</button></div> : null}
				</div>

			</div>
		)

	}

}

export default FieldRender;