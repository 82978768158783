import React, { Component } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import PinInput from 'react-pin-input';
import DocumentPage from '../components/DocumentPage.js';
import Fade from 'react-reveal/Fade';
import './css/DocumentSigner.css';

class DocumentSigner extends Component {

	constructor(props) {

		super(props);

		this.state = {
			private_token: '',
			...this.props,
			document_signer_step: 1,
			documents: {},
			datas: {},
			references: {},
			document: {},
			brands: {},
			brand: {},
			page_index: 0,
			document_signer_interval_timer: 1,
			sending_pin: false
		}
	}

	componentWillMount() {
	}

	componentDidMount() {
		this.refs.pincode.focus();

		if (this.state.private_token && this.state.private_token !== '') {
			this.getBrands();
		}
	}

	getBrands() {

		console.log('get brands', this.state.private_token);

		this.setState({ sending_pin: true });

		let headers = {
			"Authority": "BLUEDESKAPP",
			"DeviceID": this.state.device_id,
			"SecurityToken": this.state.security_token
		};

		axios.get(
			this.state.api_domain + "/fr/api/documentsigner/v1/get_brands", {
			params: {},
			headers: headers
		}
		)
			.then(res => {

				if (res.data.status === true) {

					if (res.data.brands.length > 0) {

						if (res.data.brands.length === 1) {
							this.setState({
								document_signer_step: 3,
								sending_pin: false,
								brands: res.data.brands,
								brand: res.data.brands[0]
							});
						} else {
							this.setState({
								document_signer_step: 2,
								brands: res.data.brands,
								sending_pin: false
							});
						}

					} else {
						// Redirect home
						this.setState({
							document_signer_step: 99,
							sending_pin: false
						});
					}

				} else {
					// console.log(res.data);
					this.refs.pincode.clear();
					this.setState({ sending_pin: false });
				}

			})
			;
	}

	onPinComplete(value) {

		// console.log('onpincomplete', value);

		this.setState({ sending_pin: true });

		let headers = {
			"Authority": "BLUEDESKAPP",
			"DeviceID": this.state.device_id,
			"SecurityToken": this.state.security_token
		};

		axios.post(
			this.state.api_domain + "/fr/api/documentsigner/v1/verify_pin",
			{ value: value },
			{ headers: headers }
		)
			.then(res => {

				if (res.data.status === true) {

					if (res.data.brands.length > 0) {

						if (res.data.brands.length === 1) {
							this.setState({
								document_signer_step: 3,
								sending_pin: false,
								brands: res.data.brands,
								brand: res.data.brands[0]
							});
						} else {
							this.setState({
								document_signer_step: 2,
								brands: res.data.brands,
								sending_pin: false
							});
						}

					} else {
						// Redirect home
						this.setState({
							document_signer_step: 99,
							sending_pin: false
						});
					}

				} else {
					// console.log(res.data);
					this.refs.pincode.clear();
					this.setState({ sending_pin: false });
				}

			})
			;
	}

	onClickBrandHandler(brand) {

		if (brand.documents.length === 0) {

			this.setState({
				document_signer_step: 99
			});

		} else {

			var references = {};

			if (brand.references) {
				references = brand.references;
			}

		}

		if (brand.documents.length === 1) {

			this.setState({
				brand,
				references,
				documents: brand.documents
			});

			this.onClickDocumentHandler(brand.documents[0]);

		} else {

			this.setState({
				brand,
				references,
				documents: brand.documents,
				document_signer_step: 3
			});

		}
	}

	onClickDocumentHandler(document) {
		this.setState({
			document,
			page: document.pages[0],
			document_signer_step: 4
		});
	}

	onPageComplete(datas, references) {

		// console.log('onPageComplete', datas, references, this.state);
		// document is a reserved keyword
		let doc = this.state.document;
		doc.pages[this.state.page_index].datas = datas;
		doc.pages[this.state.page_index].references = references;

		let page_index = this.state.page_index + 1;

		if (doc.pages.length === page_index) {

			// That was last page, let's send the record to home
			// console.log('ondocumentcomplete');

			let headers = {
				"Authority": "BLUEDESKAPP",
				"DeviceID": this.state.device_id,
				"SecurityToken": this.state.security_token
			};

			axios.post(
				this.state.api_domain + "/fr/api/documentsigner/v1/send_document",
				{
					brand: this.state.brand,
					references: this.state.references,
					document: doc,
					token: this.state.private_token
				},
				{ headers }
			)
				.then(() => {

					// Trigger generator
					axios.get(this.state.api_domain + '/fr/api/documentsigner/v1/generate_documents', {}, { headers });

					// No check
					this.setState({
						document_signer_step: 5
					});

				})
				;


		} else {

			let page = doc.pages[page_index];

			this.setState({
				document_signer_step: 4,
				document: doc,
				page_index,
				page
			});
		}
	}

	onClickReturnHandler() {

		// console.log("click return", this.state.document_signer_step, this.state.page_index);

		if (this.state.document_signer_step < 3) {

			// Redirect please
			this.setState({
				document_signer_step: 99
			});


		} else if (this.state.document_signer_step === 3) {

			this.setState({
				document_signer_step: 2
			});


		} else if (this.state.document_signer_step === 4) {


			if (this.state.page_index === 0) {

				// console.log('page return from start');

				this.setState({
					document_signer_step: 3
				});

			} else {

				var page_index = this.state.page_index - 1;
				var new_index = 0;

				for (var i = page_index; i >= 0; i--) {
					if (this.state.document.pages[i].hide_this_page_app === false) {
						new_index = i;
						break;
					}
				}

				if (new_index > 0) {

					// console.log('page return from', this.state.page_index, 'to', new_index);
					var page = this.state.document.pages[new_index];

					this.setState({
						page_index: new_index,
						page
					});

				} else {

					// console.log('page return from start');

					this.setState({
						document_signer_step: 3
					});
				}

			}
		}
	}

	render() {

		if (this.state.document_signer_step === 1) {

			// Login step
			return (
				<div className="document_signer_wrapper">
					<Fade big>
						<div className="languages_wrapper">

							<div onClick={() => this.onClickReturnHandler()} className="return_to_home">
								{`< Retour`}
							</div>

							<div className="logo_wrapper">
								<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
							</div>

							<div className="access_reserved_wrapper">
								<h3>Accès Réservé</h3>

								<PinInput
									length={4}
									type="numeric"
									ref="pincode"
									style={{ padding: '10px' }}
									inputStyle={{ borderColor: 'grey' }}
									inputFocusStyle={{ borderColor: 'blue' }}
									onComplete={(value, index) => { this.onPinComplete(value); }}
								/>

								{this.state.sending_pin === true ?
									<p>Vérification du code ...</p>
									:
									<p>
										Merci de saisir le code d'accès.<br />
										Si vous représentez une entreprise extérieure,
										merci de vous adresser à l'accueil afin que l'on vous ouvre un accès.
									</p>
								}
							</div>
						</div>
					</Fade>
				</div>
			);

		} else if (this.state.document_signer_step === 2) {

			// Brand selection
			const { brands } = this.state;

			return (
				<div className="document_signer_wrapper">

					<div onClick={() => this.onClickReturnHandler()} className="return_to_home">
						{`< Retour`}
					</div>

					<div className="logo_wrapper">
						<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
					</div>

					<Fade big>

						<p>Merci de sélectionner la société que vous visitez.</p>

						<div className="brands_wrapper">
							{brands.map((brand, brand_key) =>
								<div key={brand_key} onClick={() => { this.onClickBrandHandler(brand); }} className="brand_link"><img src={brand.logo} alt={brand.name} /></div>
							)}
						</div>

					</Fade>

				</div>
			);

		} else if (this.state.document_signer_step === 3) {

			// Document selection

			return (
				<div className="document_signer_wrapper">

					<div onClick={() => this.onClickReturnHandler()} className="return_to_home">
						{`< Retour`}
					</div>

					<div className="logo_wrapper">
						<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
					</div>

					<Fade big>

						<p>Merci de sélectionner le document que vous souhaitez remplir.</p>

						<div className="documents_wrapper">
							{this.state.documents.map((document, document_key) =>
								<div key={document_key} onClick={() => this.onClickDocumentHandler(document)} className="document_link">{document.name}</div>
							)}
						</div>

					</Fade>

				</div>
			);

		} else if (this.state.document_signer_step === 4) {

			// Document page wrapper

			return (
				<div className="document_signer_wrapper" ref={this.state.document.name}>

					<Fade big>

						<div onClick={() => this.onClickReturnHandler()} className="return_to_home">
							{`< Retour`}
						</div>

						<h1>
							{this.state.brand.name} - {this.state.document.name}
						</h1>

						<div className="documents_pages_wrapper">
							<DocumentPage key={this.state.page_index} className={`page_${this.state.page}`} references={this.state.references} document={this.state.document} page={this.state.page} index={this.state.page_index} onComplete={(datas) => { this.onPageComplete(datas); }} />
						</div>

					</Fade>
				</div>
			);

		} else if (this.state.document_signer_step === 5) {

			setTimeout(() => {

				let prev = this.state.document_signer_interval_timer;

				if (prev > 4) {
					this.setState({
						document_signer_step: 1,
						document_signer_interval_timer: 0,
						datas: {},
						references: {},
						brand: {},
						document: {},
						page_index: 0,
					});
					return (<Redirect to="/" push />);
				} else {
					this.setState({ document_signer_interval_timer: prev + 1 });
				}

			}, 1000);

			return (
				<div className="document_signer_wrapper">

					<div className="confirm">

						<div className="logo_wrapper">
							<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
						</div>

						<Fade big>
							<p>
								Ce document va vous être transmis par email d'ici quelques minutes.
							</p>

							<div className="loader_repeat">
								<img src="img/loader.gif" alt="" /> Redirection dans <span className="loading_time">{5 - this.state.document_signer_interval_timer}</span> secondes.
							</div>
						</Fade>

					</div>

				</div>
			);


		} else {
			return (
				<Redirect to="/" push />
			);
		}

	}

}

export default DocumentSigner;