import React, { Component } from 'react';
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import ReactHtmlParser from 'react-html-parser';

import '../App.css';

class Welcome extends Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.props,
			lang: '',
			phone: '',
			phone_input_status: '',
			step: 1,
			activity_timer_refresh: 5 * 60 * 1000,
			activity_interval: 0,
			sending_sms: false,
			interval_timer: 0
		}

		this.onChangeInput = this.onChangeInput.bind(this);
		this.onClickInput = this.onClickInput.bind(this);
	}

	setActivityChecker() {


		if (this.state.activity_interval > 0) {
			// console.log('clear actity checker', this.state.activity_interval);
			clearInterval(this.state.activity_interval);
		}

		let activity_interval = setInterval(() => { this.checkActivity() }, this.state.activity_timer_refresh);

		// console.log('set actity checker', activity_interval);
		this.setState({ activity_interval });

	}

	checkActivity() {

		if (this.state.step === 2) {

			// console.log('activity checker rewind to step 1');

			this.setState({
				step: 1
			});
		}

	}

	onClickInput(e) {

		if (e.target.value.length === 0) {
			e.target.value = "+";
		}
	}

	onChangeInput(e) {

		this.setState({
			[e.target.name]: e.target.value
		});
	}

	onClickFlagHandler(language) {

		this.setState({
			translation: language,
			step: 2,
		});

		this.setActivityChecker();

	}

	onClickReturnHandler() {

		this.setState({
			step: 1
		});
	}


	onClickInputHandler() {

		// console.log('onclickinput');

		let phone_number = this.state.text_input;

		// Check input before ?
		if (!this.validPhoneInput(phone_number)) {
			this.setState({ phone_input_status: 'disabled' });
			return;
		}

		this.setState({ sending_sms: true });

		// console.log(phone_number);

		let headers = {
			"Authority": "BLUEDESKAPP",
			"DeviceID": this.state.device_id,
			"SecurityToken": this.state.security_token
		};

		axios.post(
			this.state.api_domain + "/" + this.state.translation.language_iso + '/api/sms/v1/send',
			{ input: phone_number },
			{ headers: headers }
		)
			.then(res => {

				if (res.data.status !== true && typeof res.data.force_reload !== "undefined" && res.data.force_reload === true) {

					// Error
					// console.log(res.data);

					// Nasty boy, let's reload the page ;)
					this.setState({ isLogged: false });
					localStorage.setItem('login_state', '');

					if (typeof window !== 'undefined') {
						window.location.reload();
					}

				} else {

					if (res.data.status === true) {
						this.setState({ step: 3 });
					} else {
						// console.log(res.data);
						this.setState({ isLogged: false });
					}

				}


			}).then(() => {
				this.setState({ sending_sms: false });
			});

	}

	validPhoneInput(phone) {
		var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
		return re.test(phone);
	}

	render() {

		if (this.state.private_token && this.state.private_token !== '') {
			return <Redirect to='/document_signer' />
		}

		const { translations, translation, qrcode_only } = this.state;

		if (this.state.step === 1) {

			return (
				<div className="welcome_wrapper">
					<div className="languages_wrapper">

						<div className="logo_wrapper">
							<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
						</div>

						<div className="flags_wrapper">
							{translations.map((language, language_key) => language.active === true ?
								<div key={language_key} onClick={() => this.onClickFlagHandler(language)} className="flag_link"><img src={`img/flag_${language.language_iso}.jpg`} alt={language.language_full_name} /></div>
								: null
							)}
						</div>

						{
							this.state.document_signer_module === true ?
								<div className="document_signer_icon_wrapper">
									<Link to="/document_signer">
										Signatures
									</Link>
								</div>
								: null
						}

					</div>
				</div>
			);

		} else if (this.state.step === 2) {

			return (
				<div className="welcome_wrapper">

					<div className="input_wrapper">

						<div className="fake_form">

							<div className="logo_wrapper">
								<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
							</div>

							<Fade big>

								<h2>{translation.welcome_title}</h2>

								<p>{ReactHtmlParser(translation.welcome_desc)}</p>

								<div className={`fake_form_subwrapper ${qrcode_only ? 'qrcode_only' : ''}`}>

									<div className="qr_code">
										<img src={`${this.state.api_domain}/${this.state.translation.language_iso}/api/sms/v1/qrlink`} alt="QR Code" />
										<button onClick={this.onClickReturnHandler.bind(this)} className="button return_to_languages">
											{`< ${translation.return_button}`}
										</button>
									</div>

									<div className="separator">
										<span className="separator_or">{translation.or_separator}</span>
									</div>

									<div className="text_input_wrapper">
										<input type="tel" className="text_input" name="text_input" placeholder={`${translation.phone_placeholder} *`} onChange={this.onChangeInput} onClick={this.onClickInput} />
										<small>* {translation.phone_not_saved}</small>
										{this.state.sending_sms === true ?
											<div className="loader_repeat">
												<img src="img/loader.gif" alt="Loading" />
											</div>
											:
											<button onClick={this.onClickInputHandler.bind(this)} className={`button welcome_confirm ${this.state.phone_input_status}`}>{translation.send_button}</button>
										}
									</div>

								</div>

							</Fade>

						</div>

					</div>

				</div>
			);

		} else if (this.state.step === 3) {


			setTimeout(() => {

				let prev = this.state.interval_timer;

				if (prev > 4) {
					this.setState({
						step: 1,
						interval_timer: 0
					});
					// clearTi(interval);
				} else {
					this.setState({ interval_timer: prev + 1 });
				}

			}, 1000);

			return (
				<div className="welcome_wrapper">

					<div className="confirm">

						<div className="logo_wrapper">
							<img className="bluemessage_large_logo" src={this.state.brand_logo} alt={this.state.brand_name} />
						</div>

						<Fade big>
							<p>
								{translation.well_sent_text_1}
								<br />
								{translation.well_sent_text_2}
							</p>

							<div className="loader_repeat">
								<img src="img/loader.gif" alt="" /> {translation.auto_redirect} <span className="loading_time">{5 - this.state.interval_timer}</span> {translation.seconds}.
							</div>
						</Fade>

					</div>

				</div>
			);

		} else {
			this.setState({ step: 1 });
		}

	}

}

export default Welcome;