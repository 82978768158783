import React, { Component } from 'react';
import validator from 'validator';
import FieldRender from './FieldRender.js';
import TablePage from './TablePage.js';
import ReactHtmlParser from 'react-html-parser';

import './css/DocumentPage.css';

class DocumentPage extends Component {

	constructor(props) {

		super(props);

		this.state = {
			datas: {},
			...this.props
		}
	}

	componentDidMount() {

		// Autopass on mount
		if (this.state.page.hide_this_page_app && this.state.page.hide_this_page_app === true) {
			this.onComplete();
		}

		if (this.state.page.conditional_reference) {

			if (typeof this.state.references[this.state.page.conditional_reference] == 'undefined' || (typeof this.state.page.conditional_value !== 'undefined' && !this.state.references[this.state.page.conditional_reference].includes(this.state.page.conditional_value))) {
				this.onComplete();
			}
		}

	}

	onComplete() {

		var has_error = false;
		var fields = this.state.page.fields;
		var datas = this.state.datas;

		// console.log('on complete document page', fields, datas);

		if (fields && fields.length > 0) {

			fields.map((field, index_field) => {

				// if (datas[index_field]) {

				fields[index_field].error = !this.validateField(field, datas[index_field]);

				if (fields[index_field].error === true) {
					has_error = true;
				}
				// }


				return has_error;
			});

		}

		if (has_error === true) {

			let page = this.state.page;
			page.fields = fields;

			this.setState({
				page,
				has_error
			})

			// console.log('(document page) on complete props with errors ', this.state);
			return;

		} else {
			this.props.onComplete(this.state.datas, this.state.references);
			// console.log('(document page) on complete props', this.state);
		}

	}

	onFieldChange(index, value) {

		let datas = this.state.datas;

		datas[index] = value;

		let references = this.state.references;

		if (this.state.page.fields && this.state.page.fields[index] && this.state.page.fields[index].reference) {
			references[this.state.page.fields[index].reference] = value;
		}

		let page = this.state.page;


		if (typeof page.fields === 'undefined') {
			page.fields = {};
		}

		if (typeof page.fields[index] === 'undefined') {
			page.fields[index] = {};
		}

		page.fields[index].data = value;

		this.setState({
			datas,
			references,
			page
		});

	}

	validateField(input, value) {

		var test = true;

		if (input.required && input.required === true) {
			switch (input.type) {

				case 'text':

					test = value ? !validator.isEmpty(value) : false;

					break;

				case 'date':

					if (value) {

						let date = Date.parse(value.toString());

						test = !isNaN(date);

					} else {
						test = false;
					}

					break;

				case 'email':

					test = value ? validator.isEmail(value) : false;

					break;

				default:
					break;
			}
		}

		return test;

	}

	render() {

		var page_number = 0;
		var pages_length = 0;

		for (var i = 0; i < (this.state.index + 1); i++) {
			if (this.state.document.pages[i].hide_this_page_app !== true) {

				if (!this.state.document.pages[i].conditional_reference || (typeof this.state.references[this.state.document.pages[i].conditional_reference] !== 'undefined' && this.state.references[this.state.document.pages[i].conditional_reference].includes(this.state.document.pages[i].conditional_value))) {
					page_number++;
				}
			}
		}

		for (i = 0; i < this.state.document.pages.length; i++) {
			if (this.state.document.pages[i].hide_this_page_app !== true) {

				if (!this.state.document.pages[i].conditional_reference || (typeof this.state.references[this.state.document.pages[i].conditional_reference] !== 'undefined' && this.state.references[this.state.document.pages[i].conditional_reference].includes(this.state.document.pages[i].conditional_value))) {
					pages_length++;
				}
			}
		}

		return (
			<div className="document_page">
				<p className="document_page_breadcrumb">Page {page_number} / {pages_length}</p>
				<h3 className="document_page_title">
					{this.state.page.title}
				</h3>

				<div className="document_page_precontent">
					{ReactHtmlParser(this.state.page.precontent)}
				</div>

				{this.state.has_error === true ?
					<div className="page_has_error"><p>Merci de corriger les champs suivants :</p>
						<ul>
							{this.state.page.fields.map((field, index_field) => {
								return (field.error === true ? <li key={index_field}>{field.label}</li> : null)
							})}
						</ul>
					</div>
					: null}

				{this.props.page.page_type === "table_content" ?
					<TablePage {...this.state} {...this.props.page} onFieldChange={(index, value) => { this.onFieldChange(index, value); }} />
					:
					<div className={`document_page_subwrapper ${this.props.page.page_type}_page`}>
						{this.state.page.fields.map((field, index_field) =>
							<FieldRender key={index_field} references={this.state.references} index_field={index_field} {...field} onFieldChange={(index, value) => { this.onFieldChange(index, value); }} />
						)}
					</div>
				}
				<div className="clearfix"></div>
				<button className="document_page_button" onClick={(e) => this.onComplete()}>Confirmer / Passer</button>
			</div>
		);
	}

}

export default DocumentPage;