import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import axios from 'axios';
import Fade from 'react-reveal/Fade';

import DocumentSigner from './DocumentSigner.js';
import Welcome from './Welcome.js';
import '../App.css';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state =
    {
      ...this.props,
      isLogged: false,
      loading_state: false,
      translations: [],
      security_token: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {

    if (this.state.isLogged === false) {

      var searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has('dst')) {

        var token = searchParams.get('dst');
        searchParams.delete("topic");

        axios.post(
          this.state.api_domain + '/fr/api/documentsigner/v1/verify_token',
          {
            token
          }
        )
          .then(res => {

            if (res && res.data && res.data.status === true) {

              const isLogged = res.data.status;

              this.setState({
                loading_state: 'Logged, setting translations.'
              });

              res.data.translations.map((val, key) => {
                return this.getTranslation(key, val);
              });

              this.setState({
                isLogged,
                security_token: res.data.token,
                private_token: res.data.token,
                brand_name: res.data.brand_name !== false ? res.data.brand_name : 'My Company',
                brand_logo: res.data.brand_logo !== false ? res.data.brand_logo : 'img/logo.png',
                document_signer_module: res.data.document_signer_module ? res.data.document_signer_module : false,
                loading_state: false
              });
            }
          })
          ;

        //removing the param to avoid further problems

      }

    }

  }

  componentDidMount() {

    try {

      let expiration = localStorage.getItem('login_state_expiration');
      let now = new Date();
      let old_state = JSON.parse(localStorage.getItem('login_state'));

      if (old_state && expiration && now.getTime() < expiration) {
        this.setState(old_state);
      }

    } catch (e) {
      console.log('JSON Session Mismatch / Corrupted : ', e);
    }

  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getTranslation(key, language) {

    axios.get(this.state.api_domain + '/' + language + '/api/sms/v2/get_translation')
      .catch((error) => {
        // handle error
        console.log(error);
        this.setState({
          is_connected: false,
          disconnect_reason: "Problem with Internet connection, this will be refreshed as soon as it comes back.",
        });
      })
      .then(res => {

        if (res && res.data) {

          var translations = this.state.translations;
          translations[key] = res.data;

          this.setState({
            translations,
          });

          localStorage.setItem('login_state', JSON.stringify(this.state));

          return res.data;

        }
      })
  }

  onClickHandler() {

    this.setState({
      loading_state: 'Getting loggin confirmation.'
    });

    let username = this.state.username;
    let password = this.state.password;

    let headers = {
      "Authority": "BLUEDESKAPP",
      "DeviceID": this.state.device_id
    };

    axios.post(
      this.state.api_domain + '/api/sms/v2/login',
      {
        username,
        password
      },
      { headers: headers }
    )
      .then(res => {

        if (res && res.data && res.data.status === true) {

          const isLogged = res.data.status;

          this.setState({
            loading_state: 'Logged, setting translations.'
          });

          res.data.translations.map((val, key) => {
            return this.getTranslation(key, val);
          });

          this.setState({
            isLogged,
            security_token: res.data.token,
            brand_name: res.data.brand_name !== false ? res.data.brand_name : 'My Company',
            brand_logo: res.data.brand_logo !== false ? res.data.brand_logo : 'img/logo.png',
            qrcode_only: res.data.qrcode_only !== false ? res.data.qrcode_only : false,
            document_signer_module: res.data.document_signer_module ? res.data.document_signer_module : false,
            loading_state: false
          });

          console.log(res.data.qrcode_only);

          let d1 = new Date();
          d1.setMinutes(d1.getMinutes() + 180);
          let expiration = d1.getTime();

          localStorage.setItem('login_state_expiration', expiration);

        } else {

          var msg = 'Login failed, please check your credentials.';

          /*
                if (res && res.data && res.data.message) {
                  msg = res.data.message;
                }
          */

          this.setState({
            isLogged: false,
            loading_state: msg
          });

        }
      })

  }

  render() {

    if (this.state.isLogged === true) {

      return (

        <div className="flex_container">
          <Router basename={this.state.base_domain}>
            <Fade big>
              <div id="welcome">
                <Route exact path="/" component={() => <Welcome {...this.state} />} />
              </div>
              <div id="document_signer">
                <Route exact path="/document_signer" component={() => <DocumentSigner {...this.state} />} />
              </div>
            </Fade>
          </Router>
        </div>

      );

    } else {

      return (

        <div className="flex_container">
          <Fade big>
            <div id="login_form">

              <h3>Please login to gain access</h3>

              <div className="login_wrapper">

                <div id="login-notification"></div>

                <div className="login_input_wrapper">
                  <input type="email" className="text_input" name="username" placeholder="Email" onChange={this.onChange} />
                  <input type="password" className="text_input" name="password" placeholder="Password" onChange={this.onChange} />
                </div>

                <button onClick={this.onClickHandler.bind(this)} className="button welcome_login">OK</button>
                {
                  this.state.loading_state !== false ? <p>{this.state.loading_state}</p> : <p></p>
                }
              </div>
            </div>
          </Fade>
        </div>

      );
    }


  }

}

export default Login;