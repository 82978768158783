// Libs
import React, { Component } from 'react';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import * as serviceWorker from './serviceWorker';

// CSS
import './App.css';

// Pages
import Login from './pages/Login.js';

class App extends Component {

  constructor(props) {

    super(props);

    if (!localStorage.getItem('device_id')) {
      localStorage.setItem('device_id', Math.floor(Math.random() * Math.floor(300)) + '-device');
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

      // Dev
      this.state = {
        is_debug: true,
        is_connected: false,
        timer_refresh: 20 * 1000,
        connexion_checker_state: false,
        base_domain: '/',
        base_dir: '/',
        api_domain: "https://bluedesk.localhost",
        disconnect_reason: "Did't do check connection so far",
        device_id: localStorage.getItem('device_id')
      };

    } else {

      let domain_split = document.location.href.split('/app/');
      let api_domain = domain_split[0];

      // Prod
      this.state = {
        is_debug: false,
        is_connected: false,
        timer_refresh: 60 * 1000,
        connexion_checker_state: false,
        base_domain: process.env.PUBLIC_URL + '/',
        base_dir: '/app/',
        api_domain: api_domain,
        disconnect_reason: "Did't do check connection so far",
        device_id: localStorage.getItem('device_id')
      };

    }

  }

  componentDidMount() {
    this.setConnectionChecker();
    this.checkConnection();
  }

  setConnectionChecker() {

    if (this.state.connexion_checker_state === false) {
      setInterval(() => { this.checkConnection() }, this.state.timer_refresh);
      this.setState({ connexion_checker_state: true });
    }

  }

  checkVersion(hash) {

    var version_hash = '';

    if (localStorage.getItem('version_hash')) {
      version_hash = localStorage.getItem('version_hash')
    }

    if (hash !== version_hash && this.state.is_connected === true) {

      console.log('new version, deleting app cache');

      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });

      localStorage.setItem('version_hash', hash);

      serviceWorker.unregister();
      window.location.reload();

    }
  }

  checkConnection() {

    axios.get(this.state.api_domain + '/api/sms/v1/ping')
      .catch((error) => {
        // handle error
        console.log(error);
        this.setState({
          is_connected: false,
          disconnect_reason: "Problem with Internet connection, this will be refreshed as soon as it comes back.",
        });
      })
      .then(res => {

        if (res && res.data && res.data.status) {

          const is_connected = res.data.status;

          if (res.data.version_hash) {
            this.checkVersion(res.data.version_hash);
          }

          if (this.state.is_connected !== res.data.status) {
            this.setState({ is_connected });
          }

        } else {

          if (this.state.is_connected !== false) {

            this.setState({
              is_connected: false,
              disconnect_reason: "Problem with service, this might be refreshed soon.",
            });

          }
        }
      })
  }

  render() {

    if (this.state.is_connected === true) {

      return (
        <div className="app_wrapper">
          <Fade big>
            <div id="connected_wrapper">
              <Login {...this.state} />
            </div>
          </Fade>
        </div>
      );

    } else {

      return (
        <div className="app_wrapper">
          <Fade big>
            <div className="flex_container">
              <div id="disconnected_wrapper">
                <p className="text-center"><small>You are disconnected : <span>{this.state.disconnect_reason}</span></small></p>
              </div>
            </div>
          </Fade>
        </div>
      );

    }
  }
}

export default App;
