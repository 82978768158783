import React, { Component } from 'react';
import FieldRender from './FieldRender.js';
import './css/TablePage.css';

class TablePage extends Component {

	constructor(props) {

		super(props);

		var counter = 1;

		if (this.props.reference && this.props.references && this.props.references[this.props.reference]) {
			counter = parseInt(this.props.references[this.props.reference]);
		}

		if (this.props.columns[0] && this.props.columns[0].reference) {

			var split = this.props.columns[0].reference.split('|').length;

			if (split > counter) {
				counter = split;
			}

		}

		if (counter < 1) {
			counter = 1;
		}

		this.state = {
			datas: {},
			fields: {},
			counter,
			...this.props
		}

	}

	componentDidMount() {

		if (this.state.datas && this.state.datas.length > this.state.counter) {
			this.setState({ counter: this.state.datas.length });
		}

	}

	onComplete() {
		this.props.onComplete(this.state.datas, this.state.references);
		// console.log('(Table page) on complete props', this.state);
	}

	onFieldChange(index, value) {
		this.props.onFieldChange(index, value);
	}

	renderRows() {

		var rows = [];

		for (let i = 0; i < this.state.counter; i++) {

			rows.push(
				<div key={i} className="table_page_row">
					{this.state.columns && this.state.columns.map((column, index_column) => {

						var reference = "";

						if (column.reference && column.reference.split('|').length > 0) {

							if (column.reference.split('|')[i]) {
								reference = column.reference.split('|')[i];
							}
							// console.log(column.reference, i, reference);
						}

						var content = "";

						if (column.content) {
							content = column.content;
						}

						if (this.state.datas && this.state.datas[i + '_column_' + index_column]) {
							content = this.state.datas[i + '_column_' + index_column];
						}

						return (
							<div key={index_column} className={`table_page_content_${index_column}`}>
								<FieldRender
									{...this.state}
									reference={reference}
									content={content}
									type={column.type ? column.type : ""}
									required={column.required ? column.required : ""}
									hidden_label={true}
									key={`${i}_column_${index_column}`}
									index_field={`${i}_column_${index_column}`}
									onFieldChange={(index, value) => { this.onFieldChange(index, value); }}
								/>
							</div>
						)
					}
					)}
				</div>
			);

		}

		return rows;
	}

	addRow() {

		var c = this.state.counter;
		var counter = c + 1;

		this.setState({
			counter
		});

		// console.log('(Table page) add row, now : ', counter);
	}

	render() {
		return (
			<div className={`document_page_subwrapper ${this.props.page_type}_page`}>
				<div className="table_page_thead">
					{this.state.columns && this.state.columns.map((column, index_column) =>
						<div className="table_column" key={index_column}>{column.label}</div>
					)}
				</div>
				<div className="table_page_tbody">
					{this.renderRows()}
				</div>
				<button className="table_page_add_button" onClick={() => { this.addRow(); }}>+ Ajouter une ligne</button>
			</div>
		);
	}

}

export default TablePage;